import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IEntity } from '../../models/entity.model';

@Injectable({
    providedIn: 'root',
})
export class ModalActionsService {
    private modalOpenCloseRegisterSubject = new Subject<boolean>();
    private modalOpenCloseLoginSubject = new Subject<boolean>();
    private modalOpenCloseActivateSubject = new Subject<{email:string, action: boolean}>();
    private modalSaveDataSubject = new Subject<IEntity>();

    sendOpenCloseModalRegisterObservable = this.modalOpenCloseRegisterSubject.asObservable();
    sendOpenCloseModalLoginObservable = this.modalOpenCloseLoginSubject.asObservable();
    sendOpenCloseModalActivateObservable = this.modalOpenCloseActivateSubject.asObservable();
    sendModalSaveObservable = this.modalSaveDataSubject.asObservable();

    public sendOpenCloseModalRegister(action: boolean) {
        this.modalOpenCloseRegisterSubject.next(action);
    }
    public sendOpenCloseModalLogin(action: boolean){
        this.modalOpenCloseLoginSubject.next(action);
    }
    public sendOpenCloseModalActivate(email: string,action: boolean) {
        this.modalOpenCloseActivateSubject.next({email,action});
    }
    public sendModalSave(entity: IEntity) {
        this.modalSaveDataSubject.next(entity);
    }
}
