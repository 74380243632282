import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HelperService } from '../utils/helpers';;
import {ITypeWorkflow, TypeWorkflow} from "../models/typeworkflows.model";
import { environment } from '../environments/environment';
import {AbstractService} from "./abstract.service";
@Injectable({
    providedIn: 'root',
})
export class TypeWorkflowService extends AbstractService<ITypeWorkflow>  {
  protected static urlTypeWorkflows : string = environment.urlServer.concat('api/typeworkflows');

  constructor(private httpClient: HttpClient) {
    super(httpClient, TypeWorkflowService.urlTypeWorkflows);}

  listGlobalStatus(): Observable<HttpResponse<ITypeWorkflow>> {
    return this.httpClient.get<ITypeWorkflow>(`${TypeWorkflowService.urlTypeWorkflows}`,
        {observe: 'response', headers: HelperService.options() });
  }
}
