import { Directive, forwardRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validators } from '@angular/forms';

const EMAIL_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => EmailValidator),
  multi: true
};

@Directive({
  selector: '[appEmailValidator][formControl],[appEmailValidator][formControlName]',
  providers: [ EMAIL_VALIDATOR ],
})
export class EmailValidator implements Validators {
  validate(control: AbstractControl): ValidationErrors | null {
    return emailValidator(control);
  }
}

export function emailValidator(control: AbstractControl): ValidationErrors | null {
  let invalid: boolean = true;
  let regExp: RegExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (control.value && control.value.match(regExp)) {
    return null;
  }
  return {'pattern': invalid};
}
