import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule, NoopAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeComponent} from './home/home.component';
import {FooterComponent} from './footer/footer.component';
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {ReactiveFormsModule} from "@angular/forms";
import { IdentificationValidator } from '../directives/Validators/identification.validator.directive';
import { BirthDateValidator } from '../directives/Validators/birth-date.validator.directive';
import { EmailValidator } from '../directives/Validators/email.validator.directive';
import { DepartureDateValidator } from '../directives/Validators/departure-date-validator.directive';
import { LoadingComponent } from './loading/loading.component';
import { RegistrationComponent } from './authentication/registration/registration.component';
import { DialogModule } from "primeng/dialog";
import { PasswordModule } from "primeng/password";
import { ToastModule } from "primeng/toast";
import { MessageService } from "primeng/api";
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { LoginComponent } from "./authentication/login/login.component";
import { ActivateComponent } from "./authentication/activate/activate.component";
import { HeaderComponent } from './header/header.component';
import { WorkflowsComponent } from './workflows/workflows.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { RatingServicesComponent } from './rating-services/rating-services.component';
import { FormsModule } from '@angular/forms';
import { WorkflowService } from "../services/workflow.service";
import {NgbModule, NgbNavModule, NgbRatingModule} from "@ng-bootstrap/ng-bootstrap";
import { UserDataComponent } from './my-profile/user-data/user-data.component';
import {UpdateFormGlobalComponent} from "./update-form-global/update-form-global.component";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FooterComponent,
    IdentificationValidator,
    BirthDateValidator,
    EmailValidator,
    DepartureDateValidator,
    LoadingComponent,
    RegistrationComponent,
    LoginComponent,
    ActivateComponent,
    HeaderComponent,
    WorkflowsComponent,
    MyProfileComponent,
    RatingServicesComponent,
    UserDataComponent,
    UpdateFormGlobalComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'es',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ReactiveFormsModule,
    DialogModule,
    PasswordModule,
    ToastModule,
    NgxUiLoaderModule,
    FormsModule,
    NoopAnimationsModule,
    NgbRatingModule,
    NgbNavModule,
    NgbModule,
  ],
  exports: [TranslateModule],
  providers: [MessageService, WorkflowService],
  bootstrap: [AppComponent]
})
export class AppModule {
}
