import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ILogin } from 'src/models/authentication/login.model';
import { AbstractService } from './abstract.service';
import { Observable } from 'rxjs';
import { HelperService } from 'src/utils/helpers';
import { Registration } from 'src/models/authentication/registration.model';
import {
  GenericResponse,
  IGenericResponse,
} from 'src/models/authentication/generic-response.model';
import { Activate } from '../models/authentication/activate.model';
import { IUser } from '../models/users.model';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService extends AbstractService<ILogin> {
  static urlLogin = `${environment.urlServer}api/auth/login`;
  static urlRegistration = `${environment.urlServer}api/auth/register`;
  static urlActivation = `${environment.urlServer}api/auth/activate`;
  static urlRefresh = `${environment.urlServer}api/auth/refresh`;
  static urlLogout = `${environment.urlServer}api/auth/logout`;
  static urlIsLogin = `${environment.urlServer}api/user`;

  constructor(httpClient: HttpClient) {
    super(httpClient, AuthenticationService.urlLogin);
  }

  refreshToken(loginForm: ILogin): Observable<HttpResponse<ILogin>> {
    return this.http.post<ILogin>(AuthenticationService.urlRefresh, loginForm, {
      observe: 'response',
      headers: HelperService.options(),
    });
  }

  logout(): Observable<HttpResponse<Object>> {
    return this.http.post(
      AuthenticationService.urlLogout,
      {},
      { observe: 'response', headers: HelperService.options() }
    );
  }

  registration(
    registrationForm: Registration
  ): Observable<HttpResponse<IGenericResponse>> {
    return this.http.post(
      AuthenticationService.urlRegistration,
      registrationForm,
      { observe: 'response', headers: HelperService.options() }
    );
  }

  activate(activateForm: Activate): Observable<HttpResponse<IGenericResponse>> {
    return this.http.post<IGenericResponse>(
      AuthenticationService.urlActivation,
      activateForm,
      { observe: 'response', headers: HelperService.options() }
    );
  }

  isLoggin(): Observable<HttpResponse<ILogin[]>> {
    return this.http.get<ILogin[]>(AuthenticationService.urlIsLogin, {
      observe: 'response',
      headers: HelperService.options(),
    });
  }

  isLogged(): Observable<HttpResponse<IUser>> {
    return this.http.get<IUser>(AuthenticationService.urlIsLogin, {
      observe: 'response',
      headers: HelperService.options(),
    });
  }

  saveToken(refreshToken: string): void {
    localStorage.setItem('access_token', refreshToken);
  }

  getToken(): string {
    let accessToken = localStorage.getItem('access_token');
    return accessToken !== null ? accessToken : '';
  }

  saveRefreshToken(refreshToken: string): void {
    localStorage.setItem('refresh_token', refreshToken);
  }

  getRefreshToken(): string {
    let refreshToken = localStorage.getItem('refresh_token');
    return refreshToken !== null ? refreshToken : '';
  }
}
