<p-dialog
  [(visible)]="showDialogPage"
  [modal]="true"
  [breakpoints]="{'60rem': '80vw', '40rem': '100vw'}"
  [style]="{width: '50vw'}"
  [showHeader]="false"
  [draggable]="false"
  [closeOnEscape]="false"
  [dismissableMask]="true"
  [positionLeft]="0"
  [positionTop]="0"
  [responsive]="true"
  [blockScroll]="true"
>
  <div class="row p-0 m-0">
    <div class="col-md-6  p-0 d-none d-sm-block img-register"></div>
    <form [formGroup]="codeInsertForm" (ngSubmit)="ngInsertCodeSubmit(codeInsertForm.value)" autocomplete="off"
          class="col-md-6  d-flex flex-column justify-content-center align-items-center m-0 p-0">
      <div class="col-md-12 p-4">
        <div class="row py-4">
          <h5 class="col-md-12 text-center font-weight-bold">{{ 'authentication.codeInsert.title' | translate }}</h5>
          <small class="col-md-12 text-center">{{ 'authentication.codeInsert.detail' | translate }}</small>
          <br/>
          <small class="col-md-12 text-center to_login">{{ 'authentication.registration.subtitle' | translate }}
            <a style="cursor:pointer;"
               (click)="viewLogin()">{{ 'authentication.registration.anclaLogin' | translate }}</a></small>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="code_activate"
                     class="col-12 form-label p-0">{{ 'authentication.codeInsert.label' | translate }}</label>
              <input type="text" maxlength="6" minlength="6" id="code_activate" required="required" name="code_activate"
                     formControlName="code_activate" class="col-12 form-control">
            </div>
          </div>
        </div>
        <button type="submit" class="btn btn-primary btn-block mt-3 mb-3" [disabled]="!codeInsertForm.valid">
          {{ 'action.activate' | translate }}
        </button>
      </div>
    </form>
  </div>
</p-dialog>
