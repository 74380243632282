<div class="row">
  <div class="col-md-12 content-workflow">
    <div class="card card-workflow">
      <div class="card-header">
        <div class="row">
          <div class="col-md-5">
            <h4 class="card-title title-workflow"> {{ titlePage | translate }} </h4>
          </div>
          <div class="col-md-3">
            <div class="input-group no-border">
              <input type="text" value="" class="form-control" [(ngModel)]="query"
                     placeholder="{{'workflows.search'|translate}}..." autofocus (keydown.enter)="searchInfo()">
                <div class="input-group-append">
                  <div class="input-group-text">
                    <i class="bi bi-search"></i>
                  </div>
                </div>
            </div>
          </div>
          <div class="col-md-4">
            <button type="button" class="btn btn-primary btn-simple mt-0" (click)="searchInfo()">
              <i class="now-ui-icons loader_refresh"></i>
              {{ 'workflows.search' | translate }}
            </button> &ngsp;
            <button type="button" class="btn btn-secondary btn-simple mt-0" (click)="reloadTable()">
              <i class="now-ui-icons loader_refresh"></i>
              {{ 'workflows.clear' | translate }}
            </button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="col-md-12">
          <table class="table table-striped table-sm">
            <thead class="text-primary table-workflow">
            <th class="text-center " style="vertical-align: middle !important;" scope="col"
                *ngFor="let thHead of listHeaders;">
              <i class="fas fa-arrow-up" *ngIf="thHead.isAsc" (click)="onAsc(thHead.label)" ></i>
              {{thHead.label|translate}}
              <i class="fas fa-arrow-down" *ngIf="thHead.isDesc" (click)="onDesc(thHead.label)" ></i>
            </th>
            </thead>
            <tbody>
            <tr *ngFor="let workflow of workflows.data;">
              <td style="vertical-align: middle !important;">
                <div class="ml-3">
                  Trámite #{{workflow.id}}<br/>
                  <label [innerHtml]="workflow.invoice_proforma?.legend"></label><br/>
                  Días: {{workflow.invoice_data_trip?.days}}<br/>
                </div>
              </td>
              <td class="text-center" style="vertical-align: middle !important;">
                <div class="ml-2">
                  {{workflow.lawyer?.full_name}}
                </div>
              </td>
              <td class="text-center" style="vertical-align: middle !important;">
                <div class="ml-2">
                  {{workflow.date_in}}
                </div>
              </td>
              <td style="vertical-align: middle !important;">
                <div class="ml-1">
                  <table>
                    <tbody>
                    <tr class="table-documents" *ngFor="let file of workflow.files;">
                      <a href="{{file.root_path}}" target="_blank">
                        <td class="text-center documents-no-border" *ngIf="file.name">
                            <img *ngIf="file.ext == 'pdf'" src="../../../assets/images/file.png" class="image-link-style" alt="pdf file">
                            <img *ngIf="file.ext != 'pdf'" src="../../../assets/images/any-file.png" class="image-link-style" alt="pdf file">
                        </td>
                        <td class="text-left documents-no-border" *ngIf="file.name">
                          {{file.name}}
                        </td>
                      </a>
                    </tr>
                    <tr class="table-documents" *ngFor="let file of workflow.invoice_documents;">
                      <a href="{{file.root_path}}" target="_blank">
                        <td class="text-center documents-no-border" *ngIf="file.name">
                          <img *ngIf="file.ext == 'pdf'" src="../../../assets/images/file.png" class="image-link-style" alt="pdf file">
                          <img *ngIf="file.ext != 'pdf'" src="../../../assets/images/any-file.png" class="image-link-style" alt="pdf file">
                        </td>
                        <td class="text-left documents-no-border" *ngIf="file.name">
                          {{file.name}}
                        </td>
                      </a>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </td>
              <td style="vertical-align: middle !important;">
                <div class="text-left ml-1">
                  <ul class="steps">
                    <li class="step"*ngFor="let status of getStatusWorkflow(workflow);">
                      <div class="step-content step-success" [attr.data-tooltip]="status.name" *ngIf="status.status == FLAG_SUCCESS">
                        <span class="step-circle">{{allStatus.indexOf(status)+1}}</span>
                      </div>
                      <div class="step-content" [attr.data-tooltip]="status.name" *ngIf="status.status == FLAG_PENDING">
                        <span class="step-circle">{{allStatus.indexOf(status)+1}}</span>
                      </div>
                      <div class="step-content step-active" [attr.data-tooltip]="status.name" *ngIf="status.status == FLAG_ACTIVE">
                        <span class="step-circle">{{allStatus.indexOf(status)+1}}</span>
                      </div>
                      <div class="step-content step-error" [attr.data-tooltip]="status.name" *ngIf="status.status == FLAG_ERROR">
                        <span class="step-circle">{{allStatus.indexOf(status)+1}}</span>
                      </div>
                    </li>
                  </ul>
                </div>

                <div class="text-left ml-2 mt-2 text-center"><br/>
                  <span *ngIf="workflow.type_status?.name == null">{{workflow.status?.name}}</span>
                  <span>{{workflow.type_status?.name}}</span>
                </div>

              </td>

              <td class="text-left" style="vertical-align: middle !important;">
                <div class="col-md-12 ml-2">
                  <button type="button" class="btn btn-primary btn-simple mt-0 button-admin"
                    *ngIf="continueWorkflow(workflow)" (click)="returnToWorkFlow(workflow)" >
                    <i class="now-ui-icons loader_refresh"></i>
                    {{ 'workflows.button_continue' | translate }}
                  </button> <br/>
                  <button type="button" class="btn btn-primary btn-simple mt-0 button-admin" style="display: none;">
                    <i class="now-ui-icons loader_refresh"></i>
                    {{ 'workflows.button_admin_documents' | translate }}
                  </button> <br/>
                  <button type="button" class="btn btn-primary btn-simple mt-0 button-admin" style="display: none;">
                    <i class="now-ui-icons loader_refresh"></i>
                    {{ 'workflows.button_admin_status' | translate }}
                  </button>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row" style="margin-top: 3rem; margin-bottom: 5rem;">
      <div class="col-md-12 text-center">
        <button type="button" class="btn btn-secondary btn-simple mt-0" routerLink="/home" >
          <i class="now-ui-icons loader_refresh"></i>
          {{ 'workflows.button_start' | translate }}
        </button> &ngsp;
        <button type="button" class="btn btn-secondary btn-simple mt-0 temporal-hidden">
          <i class="now-ui-icons loader_refresh"></i>
          {{ 'workflows.button_services' | translate }}
        </button> &ngsp;
        <button type="button" class="btn btn-secondary btn-simple mt-0 temporal-hidden">
          <i class="now-ui-icons loader_refresh"></i>
          {{ 'workflows.button_blog' | translate }}
        </button> &ngsp;
        <button type="button" class="btn btn-secondary btn-simple mt-0 temporal-hidden">
          <i class="now-ui-icons loader_refresh"></i>
          {{ 'workflows.button_to_change' | translate }}
        </button> &ngsp;
        <button type="button" class="btn btn-secondary btn-simple mt-0 temporal-hidden">
          <i class="now-ui-icons loader_refresh"></i>
          {{ 'workflows.button_to_review' | translate }}
        </button> &ngsp;
        <button type="button" class="btn btn-secondary btn-simple mt-0 temporal-hidden">
          <i class="now-ui-icons loader_refresh"></i>
          {{ 'workflows.button_contact' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
