import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, AbstractControl } from '@angular/forms';
import { appConfigs } from '../constants/initialize';
import { HttpParams } from '@angular/common/http';
import { AbstractPluckList } from '../../models/abstractplucklist.model';


export class ControlsComponent {
    protected page = appConfigs.page_init;
    protected per_page = appConfigs.per_page;
    protected column = appConfigs.column_default;
    protected orientation = appConfigs.orientationAsc;
    public query = '';
    protected action = appConfigs.actionAdd;
    protected permissions: string [];
    modalOptions: NgbModalOptions;
    constructor(protected ngxService: NgxUiLoaderService,
                protected modalService: NgbModal,
                protected formBuilder: FormBuilder) {
        this.modalOptions = {
            backdrop: 'static',
            backdropClass: 'customBackdrop',
        };
        this.permissions = this.getPermissions();
    }
    getFormBuilder() {
        return this.formBuilder;
    }
    loadPage(page: number) {
        this.page = page;
        this.searchInfo();
    }
    reloadTable() {
        this.page = appConfigs.page_init;
        this.query = '';
        this.searchInfo();
    }

    onDesc(column: string) {
        this.page = appConfigs.page_init;
        this.column = column;
        this.orientation = appConfigs.orientationDesc;
        this.searchInfo();
    }

    onAsc(column: string) {
        this.page = appConfigs.page_init;
        this.column = column;
        this.orientation = appConfigs.orientationAsc;
        this.searchInfo();
    }

    httpParamsLists() {
       return new HttpParams()
            .set('per_page', String(this.per_page))
            .set('page', String(this.page))
            .set('sort_by', String(this.column))
            .set('orientation_by', String(this.orientation))
            .set('page', String(this.page))
            .set('q', this.query);
    }
    searchInfo() {
    }

    getPermissions(): string[] {
      const user = JSON.parse(localStorage.getItem('user') ?? "");
      return user?.my_permission??[];
    }

    canAction(name: string): boolean {
        return this.permissions.includes(name);
    }
}
