<div class="row">
  <div class="col-md-12 m-5">
    <img src="../../../assets/images/mi_perfil.png" class="image-profile-style" alt="profile">
  </div>
  <div class="col-md-12 m-10" style="text-align: center;">
    <a role="link" aria-disabled="true">
      <img src="../../../assets/images/my_payments.png" class="image-style" alt="my_payments">
    </a>
    <a role="link" aria-disabled="true">
      <img src="../../../assets/images/my_notifications.png" class="image-style" alt="my_notifications">
    </a>
    <a role="link" routerLink="/workflows" >
      <img src="../../../assets/images/my_workflows.png" class="image-style" alt="my_workflows">
    </a>
    <a role="link" routerLink="/profile" >
      <img src="../../../assets/images/my_profile.png" class="image-style" alt="my_profile">
    </a>
  </div>
</div>
