import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HelperService } from '../utils/helpers';
import { environment } from '../environments/environment';
import {AbstractService} from "./abstract.service";
import {IRateQuestions} from "../models/rate-questions.model";
import {IEntity} from "../models/entity.model";
import {IWorkflow, Workflow} from "../models/workflow.model";
import {IRatingService} from "../models/rating-service.model";
@Injectable({
    providedIn: 'root',
})
export class RateQuestionsService extends AbstractService<IRateQuestions>  {
  protected static urlBase : string = environment.urlServer.concat('api/guest/rate-questions');
  protected static urlRatingValidation : string = environment.urlServer.concat('api/guest/rating-validation');
  protected static urlSaveRating : string = environment.urlServer.concat('api/guest/rating-service');

  constructor(private httpClient: HttpClient) {
    super(httpClient, RateQuestionsService.urlBase);}

  listQuestions(set?: string): Observable<HttpResponse<IRateQuestions[]>> {
    set = set ?? 'BASE';
    return this.httpClient.get<IRateQuestions[]>(`${RateQuestionsService.urlBase}/${set}`,
        {observe: 'response', headers: HelperService.options() });
  }

  isRatingValid(id: string): Observable<HttpResponse<IWorkflow>> {
    return this.http.get<IWorkflow>(`${RateQuestionsService.urlRatingValidation}/${id}`, {
      observe: 'response',
      headers: HelperService.options()
    });
  }

  saveRating(rating: IRatingService): Observable<HttpResponse<IRatingService>> {
    return this.http.post<IRatingService>(`${RateQuestionsService.urlSaveRating}`, rating, {
      observe: 'response',
      headers: HelperService.options()
    });
  }
}
