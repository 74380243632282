<h1 class="d-flex justify-content-center "><strong>{{'updateFormGlobal.titleRoot' | translate}}</strong></h1>

<div class="d-flex justify-content-center ">
  <div class="col-6 col-lg-6">
    <div class="row">
      <div class="card-body">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
          <li [ngbNavItem]="1">
            <a ngbNavLink>{{'updateFormGlobal.datosDeLosViajeros' | translate}}</a>
            <ng-template ngbNavContent>
              <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                <div *ngFor="let data of travelers" class="panel panel-default">
                  <div class="panel-heading" role="tab" [id]="'heading'+data.id">
                    <h5 class="panel-title">
                      {{'updateFormGlobal.viajero' | translate}}  {{data.id}}
                    </h5>
                  </div>
                  <div [id]="'collapse'+data.id" [attr.aria-labelledby]="'heading'+data.id">
                    <div class="panel-body">
                      <div>
                        <div class="form-group">
                          <label class="d-flex justify-content-start form-control-label"
                                 for="numeroCedula">{{'updateFormGlobal.numeroCedula' | translate}}</label>
                          <input [(ngModel)]="data.nui" class="col-6 form-control" type="text" id="numeroCedula"
                                 name="nui" [disabled]="disabledForm">
                        </div>
                      </div>
                      <div>
                        <div class="form-group">
                          <label class="d-flex justify-content-start form-control-label"
                                 for="nombreCompleto">{{'updateFormGlobal.nombreCompleto' | translate}}</label>
                          <input [(ngModel)]="data.full_name" class="col-6 form-control" type="text" id="nombreCompleto"
                                 [disabled]="disabledForm"
                                 name="nui">
                        </div>
                      </div>

                      <div>
                        <div class="form-group">
                          <label class="d-flex justify-content-start form-control-label"
                                 for="fechaNacimiento">{{'updateFormGlobal.fechaNacimiento' | translate}}</label>
                          <input [(ngModel)]="data.birthdate" type="date" class="col-6 form-control"
                                 id="fechaNacimiento" [disabled]="disabledForm"
                                 name="nui">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="2">
            <a ngbNavLink>{{'updateFormGlobal.datosOtorgantes' | translate}}</a>
            <ng-template ngbNavContent>
              <div class="panel-group" id="accordionOtorgante" role="tablist" aria-multiselectable="true">
                <div *ngFor="let data of grantors" class="panel panel-default">
                  <div class="panel-heading" role="tab" [id]="'heading'+data.id">
                    <h5 class="panel-title">
                      {{'updateFormGlobal.otorgante' | translate}}  {{data.id}}
                    </h5>
                  </div>
                  <div [id]="'collapse'+data.id" [attr.aria-labelledby]="'heading'+data.id">
                    <div class="panel-body">
                      <div>
                        <div class="form-group">
                          <label class="d-flex justify-content-start form-control-label"
                                 for="numeroCedulaO">{{'updateFormGlobal.numeroCedula' | translate}}</label>
                          <input [(ngModel)]="data.nui" class="col-6 form-control" type="text" id="numeroCedulaO"
                                 name="nui" [disabled]="disabledForm">
                        </div>
                      </div>
                      <div>
                        <div class="form-group">
                          <label class="d-flex justify-content-start form-control-label"
                                 for="nombreCompletoO">{{'updateFormGlobal.nombreCompleto' | translate}}</label>
                          <input [(ngModel)]="data.full_name" class="col-6 form-control" type="text"
                                 id="nombreCompletoO" name="nui" [disabled]="disabledForm">
                        </div>
                      </div>

                      <div>
                        <div class="form-group">
                          <label class="d-flex justify-content-start form-control-label"
                                 for="direccionDomiciliaria">{{'updateFormGlobal.direccionDomiciliaria' | translate}}</label>
                          <input [(ngModel)]="data.address" class="col-6 form-control" type="text"
                                 id="direccionDomiciliaria" name="nui" [disabled]="disabledForm">
                        </div>
                      </div>
                      <div>
                        <div class="form-group">
                          <label class="d-flex justify-content-start form-control-label"
                                 for="telefono">{{'updateFormGlobal.telefono' | translate}}</label>
                          <input [(ngModel)]="data.phone" class="col-6 form-control" type="text" id="telefono"
                                 name="nui" [disabled]="disabledForm">
                        </div>
                      </div>
                      <div>
                        <div class="form-group">
                          <label class="d-flex justify-content-start form-control-label"
                                 for="correoElectronico">{{'updateFormGlobal.correoElectronico' | translate}}</label>
                          <input [(ngModel)]="data.email" class="col-6 form-control" type="email" id="correoElectronico"
                                 name="nui" [disabled]="disabledForm">
                        </div>
                      </div>
                      <div>
                        <div class="form-group">
                          <label class="d-flex justify-content-start form-control-label"
                                 for="profesion">{{'updateFormGlobal.profesion' | translate}}</label>
                          <input [(ngModel)]="data.profession" class="col-6 form-control" type="text" id="profesion"
                                 name="nui" [disabled]="disabledForm">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="3">
            <a ngbNavLink>{{'updateFormGlobal.datosAcompaniante' | translate}}</a>
            <ng-template ngbNavContent>
              <div class="panel panel-default">
                <div>
                  <div class="form-group">
                    <label class="d-flex justify-content-start form-control-label"
                           for="parentesco">{{'updateFormGlobal.parentesco' | translate}}</label>
                    <select class="form-control" id="parentesco" name="parentesco" [(ngModel)]="accompanies.type_id"
                            [disabled]="disabledForm">
                      <option
                        [ngValue]="companionOption.id"
                        *ngFor="let companionOption of companions">{{ companionOption.label }}</option>
                    </select>

                  </div>
                </div>
                <div>
                  <div class="form-group">
                    <label class="d-flex justify-content-start form-control-label"
                           for="numeroCedulaA">{{'updateFormGlobal.numeroCedula' | translate}}</label>
                    <input [(ngModel)]="accompanies.nui" class="col-6 form-control" type="text" id="numeroCedulaA"
                           name="nui" [disabled]="disabledForm">
                  </div>
                </div>
                <div>
                  <div class="form-group">
                    <label class="d-flex justify-content-start form-control-label"
                           for="nombreCompletoA">{{'updateFormGlobal.nombreCompleto' | translate}}</label>
                    <input [(ngModel)]="accompanies.full_name" class="col-6 form-control" type="text"
                           id="nombreCompletoA"
                           name="nui" [disabled]="disabledForm">
                  </div>
                </div>
              </div>

            </ng-template>
          </li>

          <li [ngbNavItem]="4">
            <a ngbNavLink>{{'updateFormGlobal.datosViaje' | translate}}</a>
            <ng-template ngbNavContent>
              <div class="panel panel-default">
                <div>
                  <div class="form-group">
                    <label class="d-flex justify-content-start form-control-label"
                           for="fechaIda">{{'updateFormGlobal.fechaIda' | translate}}</label>
                    <input [(ngModel)]="travel.departure_date" class="col-6 form-control" type="date" id="fechaIda"
                           name="nui" [disabled]="disabledForm">
                  </div>
                </div>
                <div>
                  <div class="form-group">
                    <label class="d-flex justify-content-start form-control-label"
                           for="fechaVuelta">{{'updateFormGlobal.fechaVuelta' | translate}}</label>
                    <input [(ngModel)]="travel.return_date" class="col-6 form-control" type="date" id="fechaVuelta"
                           name="nui" [disabled]="disabledForm">
                  </div>
                </div>
                <div>
                  <div class="form-group">
                    <label class="d-flex justify-content-start form-control-label"
                           for="datosTransporte">{{'updateFormGlobal.datosTransporte' | translate}}</label>
                    <input [(ngModel)]="travel.transportation_data" class="col-6 form-control" type="text"
                           id="datosTransporte" name="nui" [disabled]="disabledForm">
                  </div>
                </div>
                <div>
                  <div class="form-group">
                    <label class="d-flex justify-content-start form-control-label"
                           for="datosAlojamiento">{{'updateFormGlobal.datosAlojamiento' | translate}}</label>
                    <input [(ngModel)]="travel.accommodation_data" class="col-6 form-control" type="text"
                           id="datosAlojamiento" name="nui" [disabled]="disabledForm">
                  </div>
                </div>
                <div>
                  <div class="form-group">
                    <label class="d-flex justify-content-start form-control-label"
                           for="motivosViaje">{{'updateFormGlobal.motivosViaje' | translate}}</label>
                    <select class="form-control" id="motivosViaje" name="motivosViaje" [(ngModel)]="travel.reason_id"
                            [disabled]="disabledForm">
                      <option
                        [ngValue]="reason_idOption.id"
                        *ngFor="let reason_idOption of reasonsForTravel">
                        {{reason_idOption.label}}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
        <br/>
        <br/>
        <button class="btn btn-secondary btn-simple pl-5 pr-5 pt-2 pb-2" type="button" (click)="save()"
                [disabled]="disabledForm">
          Guardar
        </button> &nbsp;
        <button type="button" class="btn btn-secondary btn-simple pl-5 pr-5 pt-2 pb-2" routerLink="/tab-navigator">
          Cancelar
        </button>
      </div>
    </div>
  </div>
</div>



