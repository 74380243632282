import {Component, OnInit} from '@angular/core';
import {IDynamicComponent} from 'src/models/accurate-data/dynamic-component';
import {Traveller} from "../../models/traveller.model";
import {Grantor} from "../../models/grantor.model";
import {Companion, ICompanion} from "../../models/companion.model";
import {IItem} from "../../models/item.model";
import {HttpErrorResponse, HttpResponse} from "@angular/common/http";
import {TravelService} from "../../services/travel.service";
import {CompanionService} from "../../services/companions.service";
import {formatDate} from "@angular/common";
import {TravellerService} from "../../services/traveller.service";
import {IInformationGlobal} from "../../models/information-global.model";
import Swal from "sweetalert2";
import {extractErrorResponse} from "../../utils/extractErrorResponse";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-update-form-global',
  templateUrl: './update-form-global.component.html',
  styleUrls: ['./update-form-global.component.scss']
})
export class UpdateFormGlobalComponent implements OnInit, IDynamicComponent {

  public travelers: Traveller[] = [];
  public grantors: Grantor[] = [];
  public accompanies: Companion;
  public travel: any;
  public reasonsForTravel: IItem[] = [];
  companions: ICompanion[] = [];
  disabledForm = true;
  active = 1;

  constructor(private travelService: TravelService,
              private companionService: CompanionService,
              private travellerService: TravellerService,
              private translate: TranslateService,
              private activatedRoute:ActivatedRoute,) {
  }

  ngOnInit(): void {
    // @ts-ignore
    this.disabledForm = this.activatedRoute.snapshot.paramMap.get("disabledForm") as boolean;
    this.travelService.query().subscribe({
      next: (res: HttpResponse<IItem[]>) => {
        this.reasonsForTravel = res.body || [];
      }
    });

    this.companionService.query().subscribe((res: HttpResponse<ICompanion[]>) => {
      this.companions = res.body || [];
    });
    const travelersStorage = sessionStorage.getItem('travelers');
    if (travelersStorage) {
      this.travelers = JSON.parse(travelersStorage);
    }
    const grantorsStorage = sessionStorage.getItem('grantors');
    if (grantorsStorage) {
      this.grantors = JSON.parse(grantorsStorage);
    }
    const companionStorage = sessionStorage.getItem('accompanies');
    if (companionStorage) {
      this.accompanies = JSON.parse(companionStorage);
    }
    let travelFormStorage = sessionStorage.getItem('travel');
    if (travelFormStorage) {
      this.travel = JSON.parse(travelFormStorage);
      const format = 'yyyy-MM-dd';
      const locale = 'en-US';
      this.travel.departure_date = formatDate(this.travel.departure_date, format, locale);
      this.travel.return_date = formatDate(this.travel.return_date, format, locale);
    }
  }


  save() {
    let invoiceStorage = sessionStorage.getItem('invoice');
    if (invoiceStorage) {
      const invoice = JSON.parse(invoiceStorage);
      sessionStorage.setItem('travelers', JSON.stringify(this.travelers));
      sessionStorage.setItem('grantors', JSON.stringify(this.grantors));
      sessionStorage.setItem('accompanies', JSON.stringify(this.accompanies));
      sessionStorage.setItem('travel', JSON.stringify(this.travel));

      let params: IInformationGlobal = {};
      params.invoice_id = invoice.id;
      params.travelers = this.travelers;
      params.grantors = this.grantors;
      params.accompanies = this.accompanies;
      params.travel = this.travel;
      this.travellerService.updateInformationGlobal(params).subscribe((res: HttpResponse<any>) => {
          Swal.fire(this.translate.instant('updateFormGlobal.informationSusses'));
        },
        (errorResponse: HttpErrorResponse) => {
          Swal.fire(extractErrorResponse(errorResponse, true));
        }
      );
    }
  }
}
