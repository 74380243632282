<nav class="navbar navbar-expand navbar-background">
  <div class="navbar-collapse collapse w-100 order-3 dual-collapse2 ">
    <ul class="navbar-nav ml-auto">
      <li class="nav-item" *ngIf="!isLoggedIn">
        <button type="submit" class="btn btn-light btn-block" (click)="openCloseLoginPage(true)">
          {{'header.login' | translate}}
        </button>
      </li>
      <li class="nav-item" *ngIf="isLoggedIn">
        <button type="submit" class="btn btn-light btn-block" (click)="logout()">
          {{'header.logout' | translate}}
        </button>
      </li>
      <li class="nav-item" *ngIf="isLoggedIn">
        <a routerLink="/my-profile" >
          <img src="../../../assets/images/user.png" class="image-user" alt="user">
        </a>
      </li>
    </ul>
  </div>
</nav>
