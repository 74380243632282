<div class="row">
  <div class="col-md-12 mb-5 mt-5">
    <img src="../../../assets/images/rating_services.png" class="image-rating-style" alt="profile">
  </div>
</div>
<div class="row card-rating">

  <div class="col-md-12 text-center" style="height: 10rem;" *ngIf="isLoading">
    <app-loading class="container-loader"></app-loading>
    <label>{{'rating_services.loading' | translate }}</label>
  </div>

  <div class="col-md-12" *ngIf="isPending && !isLoading">
    <div class="row elements-center" >
      <div class="card" style="width: 60rem">
        <div class="card-header">
          <div class="row">
            <div class="col-md-12 text-center">
              <h4 class="card-title title-rating"> {{ titlePage | translate }} </h4>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="col-md-12">
            <table class="table table-striped table-sm">
              <thead class="text-primary table-rating">
              <th class="text-center " style="width: 50%" scope="col"></th>
              <th class="text-center " style="vertical-align: middle !important;" scope="col">
                <div class="row">
                  <div class="col-md-6">
                    {{'rating_services.min_rate'|translate}}
                  </div>
                  <div class="col-md-6">
                    {{'rating_services.max_rate'|translate}}
                  </div>
                </div>
              </th>
              </thead>
              <tbody>
              <tr *ngFor="let question of baseQuestions;">
                <td style="vertical-align: middle !important;">
                  <div class="ml-3">
                    <label class="text-rating-question">{{question.question}}</label><br/>
                  </div>
                </td>
                <td class="text-center" style="vertical-align: middle !important;">
                  <div class="ml-2">
                    <ngb-rating [id]="baseQuestions.indexOf(question)" [max]="5"
                                [(rate)]="question.value" [readonly]="isSending"
                                (rateChange)="changeValue(baseQuestions.indexOf(question), $event)">
                    </ngb-rating>
                    <!-- <pre>Model: <b>{{ question.value }}</b></pre>-->
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
      <div class="row" style="margin-top: 3rem; margin-bottom: 5rem;">
        <div class="col-md-12 text-center">
          <div class="col-md-12 text-center">
             <pre>{{statusMessage}}</pre>
          </div>
          <button type="button" class="btn btn-secondary btn-simple mt-0" (click)="saveRating()" [disabled]="isSending">
            <i class="now-ui-icons loader_refresh"></i>
            {{ 'rating_services.send' | translate }}
          </button>
        </div>
      </div>
  </div>

  <div class="col-md-12" *ngIf="!isPending && !isLoading &&!notFound">
      <div class="row">
        <div class="col-md-12 text-center">
          <h5 class="card-title title-rating"> {{ 'rating_services.is_filled' | translate }} </h5>
        </div>
      </div>
  </div>

  <div class="col-md-12" *ngIf="!isPending && !isLoading &&notFound">
      <div class="row">
        <div class="col-md-12 text-center">
          <h5 class="card-title title-rating"> {{statusMessage}}</h5>
        </div>
      </div>
  </div>

</div>

