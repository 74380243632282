import { Component, OnInit } from '@angular/core';
import { ModalActionsService } from "../../services/message/modal-actions.service";
import { LoginActionsService } from 'src/services/message/login-actions.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  isLoggedIn: boolean = false;

  constructor(private modalActionService: ModalActionsService, private loginActionService: LoginActionsService) {
  }

  ngOnInit(): void {
    this.loginActionService.loginObservable.subscribe((response) => {
      this.isLoggedIn = response;
    });
  }

  openCloseRegisterPage(action: boolean): void {
    this.modalActionService.sendOpenCloseModalRegister(action);
  }

  openCloseLoginPage(action: boolean): void {
    this.modalActionService.sendOpenCloseModalLogin(action);
  }
}
