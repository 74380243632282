import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { routerAnimations } from "../animations/router-animation";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [ routerAnimations ]
})
export class AppComponent {
  title = 'boomerang-web';
  isLoaded = false;

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData?.['animation'];
  }
}
