import {Component, Directive, OnInit} from '@angular/core';
import { Router } from "@angular/router";
import { ControlsComponent } from '../shared/controls.component';
import { appConfigs } from '../constants/initialize';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {IWorkflow, IWorkflowHead, Workflow} from "../../models/workflow.model";
import { FormStatus } from '../../utils/formStatus';
import {WorkflowService} from "../../services/workflow.service";
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import Swal from 'sweetalert2';
import { extractErrorResponse } from '../../utils/extractErrorResponse';
import {TypeWorkflow} from "../../models/typeworkflows.model";
import {TypeWorkflowService} from "../../services/type-workflow.service";
import {Observable, take} from "rxjs";
import {IRatingService} from "../../models/rating-service.model";
import {Travel} from "../../models/accurate-data/travel.model";
import {SubTabsIndex, TabsIndex} from "../../utils/tabs-index";
import {EnumGlobalStatusWorkflow} from "../../models/enums/global-status-workflow-list.model";
import {TabNavigatorComponent} from "../tab-navigator/tab-navigator.component";
import {PaymentService} from "../../services/payment.service";
import {IPayment} from "../../models/payment.model";
import {IEntity} from "../../models/entity.model";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-workflows',
  templateUrl: './workflows.component.html',
  styleUrls: ['./workflows.component.scss','./bootstrap-steps.css'],
})

export class WorkflowsComponent extends ControlsComponent implements OnInit {
  public titlePage = appConfigs.titlePageWorkflowStatus;
  public titleModal = appConfigs.titleModalWorkflow;
  public isSending = false;
  public isSaving = false;
  public hasErrors = false;
  public amountEnabledItem: number = 0;
  public islogging = false;
  public lawyerForm: FormGroup;
  public formStatus = new FormStatus();

  public catalog: TypeWorkflow[];

  public GLOBAL_TYPES_CONST: string = 'TIPOS DE TRÁMITE';
  public GLOBAL_STATUS_CONST: string = 'ESTADOS GLOBALES';
  public EXIT_COUNTRY_STATUS_CONST: string = 'SALIDA DEL PAÍS';

  public FLAG_SUCCESS = 'success';
  public FLAG_ACTIVE = 'active';
  public FLAG_PENDING = 'pending';
  public FLAG_ERROR = 'error';

  public global_status: TypeWorkflow;
  public global_type: TypeWorkflow;
  public global_type_status: TypeWorkflow;

  public global_statues: TypeWorkflow[];
  public global_types: TypeWorkflow[];
  public global_type_statues: TypeWorkflow[];
  public allStatus: TypeWorkflow[] = [];

  public dataTooltipHtmlTag: string = 'data-tooltip="';

  constructor(
    public router: Router,
    protected override ngxService: NgxUiLoaderService,
    protected override modalService: NgbModal,
    protected override formBuilder: FormBuilder,
    private workflowService: WorkflowService,
    private paymentService: PaymentService,
    private typeWorkflowService: TypeWorkflowService,
    private translate: TranslateService,
  ) {
    super(ngxService, modalService, formBuilder);
  }

  public listHeaders: IWorkflowHead[] = [
    {label: 'workflows.workflow', isAsc: true, isDesc: true},
    {label: 'workflows.lawyer', isAsc: true, isDesc: true},
    {label: 'workflows.date', isAsc: true, isDesc: true},
    {label: 'workflows.documents', isAsc: true, isDesc: true},
    {label: 'workflows.status', isAsc: true, isDesc: true},
    {label: 'workflows.actions', isAsc: false, isDesc: false}
  ];

  public returnMappingWorkflow: { step: TabsIndex; status: EnumGlobalStatusWorkflow, stepCurrentSData: SubTabsIndex }[] = [
    {status: EnumGlobalStatusWorkflow.VALUED, step: TabsIndex.tabAccurateData, stepCurrentSData: SubTabsIndex.subTabQuoted},
    {status: EnumGlobalStatusWorkflow.PAYMENT, step: TabsIndex.tabAccurateData, stepCurrentSData: SubTabsIndex.subTabQuoted},
    {status: EnumGlobalStatusWorkflow.LOAD_DATA, step: TabsIndex.tabAccurateData, stepCurrentSData: SubTabsIndex.subTabSigned},
    {status: EnumGlobalStatusWorkflow.LOAD_DOCUMENTS, step: TabsIndex.tabFilesLoad, stepCurrentSData: SubTabsIndex.subTabDocuments},
  ];

  ngOnInit(): void {
    this.searchInfo();
    this.loadSelectItems();
  }

  public workflows: IWorkflow = {
    'data': [],
  };

  loadSelectItems(): void {
    let sessionVariableStatus = 'allStatus';
    let allStatus = sessionStorage.getItem(sessionVariableStatus);
    this.allStatus = JSON.parse(allStatus ?? "[]");
    if (this.allStatus.length != 0)
      return;
    this.typeWorkflowService.listGlobalStatus()
      .pipe(take(1)).subscribe(
      (response) => {
        this.catalog = response.body?.data as TypeWorkflow[];
        this.global_type = this.catalog.find(element => element.name == this.GLOBAL_TYPES_CONST) as TypeWorkflow;
        this.global_status = this.catalog.find(element => element.name == this.GLOBAL_STATUS_CONST) as TypeWorkflow;
        this.global_type_status = this.catalog.find(element => element.name == this.EXIT_COUNTRY_STATUS_CONST) as TypeWorkflow;

        this.global_types = this.catalog.filter(element => element.parent_id == this.global_type.id)
          .sort((a, b) => (a.order > b.order) ? 1 : -1);
        this.global_statues = this.catalog.filter(element => element.parent_id == this.global_status.id)
          .sort((a, b) => (a.order > b.order) ? 1 : -1);
        this.global_type_statues = this.catalog.filter(element => element.parent_id == this.global_type_status.id)
          .sort((a, b) => (a.order > b.order) ? 1 : -1);
        this.allStatus = this.global_statues.concat(this.global_type_statues);

        sessionStorage.setItem(sessionVariableStatus, JSON.stringify(this.allStatus));
      },
      (errorResponse: HttpErrorResponse) => {
        Swal.fire(extractErrorResponse(errorResponse, true));
      }
    );

  }

  override searchInfo(): void {
    this.ngxService.start();
    this.workflowService.queryItems(
      this.httpParamsLists()
    ).subscribe(
      async (response) => {
        this.workflows = response.body as IWorkflow;
        this.ngxService.stop();
      }, (errorResponse: HttpErrorResponse) => {
        Swal.fire(extractErrorResponse(errorResponse, true));
        this.ngxService.stop();
      }
    );
  }

  loadWorkflow(modalLawyer: any, workflow?: Workflow): void {
    this.modalOptions.size = appConfigs.largeSize;
    this.formStatus.showMessages.error = false;
    this.formStatus.showMessages.success = false;
    this.formStatus.messages = [];
    this.action = appConfigs.actionAdd;
    this.lawyerForm.reset();
    if (workflow) {
      this.lawyerForm.patchValue(workflow);
      this.action = appConfigs.actionUpdate;
    }
    this.modalService.open(modalLawyer, this.modalOptions).result.then(
      () => {
        this.reloadTable();
      },
      () => {
        this.reloadTable();
      }
    );
  }

  getStatusWorkflow(workflow: Workflow): TypeWorkflow[] {
    let statusProgress: TypeWorkflow[] = [];
    let flag = this.FLAG_SUCCESS; // green

    let validationErrorPayment: boolean = workflow.global_status_id === (this.allStatus.find(
      item => item.name === EnumGlobalStatusWorkflow.REJECTED) as TypeWorkflow).id;
    let validationNull: boolean = workflow.global_status_id == null && workflow.type_status_id == null;

    this.allStatus.forEach(status => {
      let validationGlobalOnly: boolean = workflow.global_status_id === status.id && (workflow.type_status_id == null);
      let validationType: boolean = (workflow.global_status_id != null) &&
        (workflow.type_status_id != null) && workflow.type_status_id === status.id;
      let validationTypeButDoneGlobalStatus: boolean = validationType && workflow.global_status_id === (this.allStatus.find(
        item => item.name === EnumGlobalStatusWorkflow.LOAD_DOCUMENTS) as TypeWorkflow).id

      let validationToActivateAndPendingFlags =
        (validationType && !validationTypeButDoneGlobalStatus) || // Only type
        (validationTypeButDoneGlobalStatus && status.name != EnumGlobalStatusWorkflow.LOAD_DOCUMENTS) || // Type but done
        (validationGlobalOnly); // Only global

      if (validationNull) {
        flag = this.FLAG_PENDING; // green
      } else if (validationToActivateAndPendingFlags) {
        status.status = this.FLAG_ACTIVE; // blue
        flag = this.FLAG_PENDING; // grey
        if (validationErrorPayment) {
          status.status = this.FLAG_ERROR; // red
        }
      } else {
        status.status = flag;
      }

      statusProgress.push(status);
    });

    return statusProgress;
  }

  continueWorkflow(workflow: Workflow): boolean {
    let validationContinue: boolean = workflow.status?.name == EnumGlobalStatusWorkflow.LOAD_DATA ||
      workflow.status?.name == EnumGlobalStatusWorkflow.LOAD_DOCUMENTS ||
      workflow.status?.name == EnumGlobalStatusWorkflow.VALUED ||
      workflow.status?.name == EnumGlobalStatusWorkflow.PAYMENT;

    let validationGlobalOnly: boolean = workflow.global_status_id != null && workflow.type_status_id == null;

    return validationContinue && validationGlobalOnly;
  }

  returnToWorkFlow(workflow: Workflow) {
    const varStep = 'step';
    const varStepCurrentData = 'step_currate_data';
    sessionStorage.removeItem(varStep)
    sessionStorage.removeItem(varStepCurrentData)

    let status = this.returnMappingWorkflow.find(e => e.status === workflow.status?.name )

    // @ts-ignore
    sessionStorage.setItem(varStep, status.step);
    // @ts-ignore
    sessionStorage.setItem(varStepCurrentData, status.stepCurrentSData as string);

    this.ngxService.start();
    this.isSending = true;

    this.paymentService.show(workflow.invoice_id+'').subscribe((response: HttpResponse<IRatingService>) => {
        sessionStorage.setItem('invoice', JSON.stringify(response.body));
        this.isSending = false;
        this.ngxService.stop();
        this.router.navigate(['/tab-navigator/item']);
      },
      (errorResponse: HttpErrorResponse) => {
        this.isSending = false;
        let statusMessage = extractErrorResponse(errorResponse, true);
        this.ngxService.stop();
        Swal.fire(statusMessage);
      });
  }

  loadInvoice(invoiceId: string) {
    this.saveInStorageInvoice(this.paymentService.show(invoiceId));
  }

  saveInStorageInvoice(result: Observable<HttpResponse<IEntity>>): void {
    result.subscribe({
      next: (res) => {
        sessionStorage.setItem('invoice', JSON.stringify(res.body));
      }
    });
  }

}
