import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from "../../services/authentication.service";
import {ModalActionsService} from "../../services/message/modal-actions.service";
import {LoginActionsService} from "../../services/message/login-actions.service";
import {Router} from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isLoggedIn : boolean;

  constructor(
    private authenticationService: AuthenticationService,
    private modalActionService: ModalActionsService,
    private loginActionService: LoginActionsService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.loginActionService.loginObservable.subscribe((response) => {
      this.isLoggedIn = response;
    });
  }

  async openCloseLoginPage(action: boolean): Promise<void> {
    await this.modalActionService.sendOpenCloseModalLogin(action);

  }

  logout(): void {
    this.authenticationService.logout().subscribe({
      next: (res) => {
        this.isLoggedIn = false;
        this.loginActionService.sendLoginAction(false);
      },
    });
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['/']);
  }

  workflows(): void {
    this.router.navigate(['/workflows']);
  }

}
