<p-dialog
  [(visible)]="showDialogPage"
  [modal]="true"
  [breakpoints]="{'60rem': '80vw', '40rem': '100vw'}"
  [style]="{width: '50vw'}"
  [showHeader]="false"
  [draggable]="false"
  [closeOnEscape]="false"
  [dismissableMask]="true"
  [positionLeft]="0"
  [positionTop]="0"
  [responsive]="true"
  [blockScroll]="true"
>
  <div class="row p-0 m-0">
    <div class="col-md-6  p-0 d-none d-sm-block img-register"></div>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" autocomplete="off"
          class="col-md-6  d-flex flex-column justify-content-center align-items-center m-0 p-0">
      <div class="col-md-12 p-4">
        <div class="row py-4">
          <h5 class="col-md-12 text-center font-weight-bold">{{ 'authentication.login.title' | translate }}</h5>
          <small class="col-md-12 text-center to_login">{{ 'authentication.login.subtitle' | translate }} <a
            style="cursor:pointer;"
            (click)="viewRegister()">{{ 'authentication.registration.title' | translate }}</a></small>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="email"
                     class="col-12 form-label p-0">{{ 'authentication.registration.email' | translate }}</label>
              <input type="text" id="email" name="email" formControlName="email" class="col-12 form-control">
              <div *ngIf="hasErrors('email')">
              <span class="col-12 text-danger p-0" *ngIf="getControlFormField('email').errors?.['required']">
                <small>{{ 'errorMessages.required' | translate }}</small>
              </span>
                <span class="col-12 text-danger p-0" *ngIf="hasCustomErrors('email', 'pattern')">
                 <small>{{ 'errorMessages.invalid_email' | translate }}</small>
              </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="password"
                     class="col-12 form-label p-0">{{ 'authentication.registration.password' | translate }}</label>
              <div class="d-flex flex-row">
                <p-password id="password" class="col-12" name="password" formControlName="password"
                            [toggleMask]="true" [feedback]="false"></p-password>
              </div>
              <div *ngIf="hasErrors('password')">
              <span class="col-12 text-danger p-0" *ngIf="getControlFormField('password').errors?.['required']">
                <small>{{ 'errorMessages.required' | translate }}</small>
              </span>
                <span class="col-12 text-danger p-0" *ngIf="getControlFormField('password').errors?.['minlength']">
                 <small>{{ 'errorMessages.min' | translate }}</small>
              </span>
              </div>
            </div>
          </div>
        </div>
        <button type="submit" class="btn btn-primary btn-block mt-5 mb-3" [disabled]="!loginForm.valid">
          {{ 'action.singIn' | translate }}
        </button>
      </div>
    </form>
  </div>
</p-dialog>
