import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {AbstractService} from "./abstract.service";
import {IDocument} from "../models/document.model";
import {HelperService} from "../utils/helpers";
import {Observable} from "rxjs";
import {IWorkflow, Workflow} from "../models/workflow.model";
import {environment} from "../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class WorkflowService extends AbstractService<IDocument> {

  private static urlWorkflows : string = environment.urlServer.concat('api/workflows');
  private static urlWorkflowsStatus : string = environment.urlServer.concat('api/user/workflows/status');

  constructor(httpClient: HttpClient) {
    super(httpClient, WorkflowService.urlWorkflows);
  }

  save(entity: IWorkflow): Observable<HttpResponse<Workflow>> {
    return this.http.post<Workflow>(`${WorkflowService.urlWorkflows}`, entity, {
      observe: 'response',
      headers: HelperService.options()
    });
  }

  updateWorkflow(entity: IWorkflow, id: number): Observable<HttpResponse<Workflow>> {
    return this.http.put<Workflow>(`${WorkflowService.urlWorkflows}/${id}`, entity,
      {
        observe: 'response',
        headers: HelperService.options()
      }
    );
  }

  queryItems(queryParams?: HttpParams): Observable<HttpResponse<Workflow>> {
    return this.http.get<Workflow>(`${WorkflowService.urlWorkflowsStatus}`, {
      params: queryParams,
      observe: 'response',
      headers: HelperService.options()
    });
  }
}
