<div class="jumbotron jumbotron-fluid">
  <div class="container">
    <div class="mx-auto text-center jumbotron-width">
      <div class="card-body">
        <br/>
        <br/>
        <h1 class="d-none d-md-block card-title text-center display-3 font-weight-bold">{{'home.title' | translate}}</h1>
        <h1 class="d-block d-sm-none card-title text-center display-4 font-weight-bold">{{'home.title' | translate}}</h1>
        <br/>
        <br class="d-block d-sm-none"/>
        <br class="d-block d-sm-none"/>

        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-md-3 col-sm-3 col-xs-3 button-padding" *ngIf="isLoggedIn" >
              <button type="submit" routerLink="/tab-navigator/item" class="btn btn-light btn-block font-format-button">
                {{'home.btn_start' | translate}}
              </button>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-3 button-padding" *ngIf="!isLoggedIn" >
              <button type="submit" (click)="openCloseLoginPage(true)" class="btn btn-light btn-block font-format-button">
                {{'home.btn_login' | translate}}
              </button>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-3 button-padding" *ngIf="!isLoggedIn" >
              <button type="submit" (click)="openCloseRegisterPage(true)" class="btn btn-light btn-block font-format-button">
                {{'home.btn_register' | translate}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="center-description">
    <div class="mx-auto footer-description">
      <p>{{'home.txt_description' | translate}}</p>
    </div>
    <br/>
  </div>
    <div class="row">
      <div class="col-md-6 col-lg-3  mb-3">
        <div class="card-info card-info-primary h-100">
          <h5 class="font-weight-bold">{{'home.whatIsIt' | translate}}</h5>
          <br/>
          <p>{{'home.whatIsItDescription' | translate}}</p>
        </div>
      </div>
      <div class="col-md-6 col-lg-3 mb-3">
        <div class="card-info card-info-secondary h-100">
          <h5 class="font-weight-bold">{{'home.whereToPresentIt' | translate}}</h5>
          <br/>
          <p>{{'home.whereToPresentItDescription' | translate}}</p>
        </div>
      </div>
      <div class="col-md-6 col-lg-3 mb-3">
        <div class="card-info card-info-primary h-100">
          <h5 class="font-weight-bold">{{'home.howLongDoesItTake' | translate}}</h5>
          <br/>
          <p>{{'home.howLongDoesItTakeDescription' | translate}}</p>
        </div>
      </div>
      <div class="col-md-6 col-lg-3 mb-3">
        <div class="card-info card-info-secondary h-100">
          <h5 class="font-weight-bold">{{'home.whatDoesServiceInclude' | translate}}</h5>
          <br/>
          <p>{{'home.whatDoesServiceIncludeDescription' | translate}}</p>
        </div>
      </div>
    </div>
  <div class="center-description">
    <br/>
    <br/>
    <div class="mx-auto footer-description">
      <h5 class="font-weight-bold">{{'home.specialists' | translate}} </h5>
    </div>
    <br/>
  </div>
</div>

