import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {AbstractService} from "./abstract.service";
import {environment} from "../environments/environment";
import {IPayment} from "../models/payment.model";
import { IEntity } from 'src/models/entity.model';
import { HelperService } from 'src/utils/helpers';
import {IProform} from "../models/proform.model";
import {Observable} from "rxjs";
import {ProformResponse} from "../models/proform-response.model";

@Injectable({
  providedIn: 'root'
})
export class PaymentService extends AbstractService<IPayment> {

  static urlCreate = 'api/create-invoice';
  static urlGet = 'api/invoice';
  static urlCreatePaymentez = 'api/paymentez/create';

  constructor(httpClient: HttpClient) {
    super(httpClient, environment.urlServer + PaymentService.urlCreate);
  }

  show(id: string) {
    return this.http.get<IEntity>(`${environment.urlServer + PaymentService.urlGet}/${id}`, {
      observe: 'response',
      headers: HelperService.options()
    });
  }

  createPayment(entity: IEntity): Observable<HttpResponse<IPayment>> {
    return this.http.post<IEntity>(environment.urlServer + PaymentService.urlCreate, entity, {
      observe: 'response',
      headers: HelperService.options()
    });
  }

  createPaymentez(entity: any): Observable<HttpResponse<IPayment>> {
    return this.http.post<IEntity>(environment.urlServer + PaymentService.urlCreatePaymentez, entity, {
      observe: 'response',
      headers: HelperService.options()
    });
  }
}
