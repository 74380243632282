import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { emailValidator } from 'src/directives/Validators/email.validator.directive';
import { GenericResponse } from 'src/models/authentication/generic-response.model';
import { AuthenticationService } from 'src/services/authentication.service';
import { ModalActionsService } from "../../../services/message/modal-actions.service";
import { MessageService } from "primeng/api";
import { extractErrorResponse } from "../../../utils/extractErrorResponse";
import { TranslateService } from "@ngx-translate/core";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Activate } from "../../../models/authentication/activate.model";
import { Registration } from "../../../models/authentication/registration.model";

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.scss']
})
export class ActivateComponent implements OnInit{
  showDialogPage = false;
  hasRegistrationErrors = false;
  codeInsertForm: FormGroup;
  viewFormCode = false;
  constructor(private formBuilder: FormBuilder,
              private authenticationService: AuthenticationService,
              private modalActionService: ModalActionsService,
              private messageService: MessageService,
              private translate: TranslateService,
              private ngxService: NgxUiLoaderService,) {
    this.createForm();
  }

  ngInsertCodeSubmit(activate : Activate): void {
     if (this.codeInsertForm.valid) {
      this.ngxService.start();
      this.authenticationService.activate(activate).subscribe({
        next: (response) => {
          this.hasRegistrationErrors= false;
          this.ngxService.stop();
          this.messageService.add({key: 'messages',severity:'success', summary:this.translate.instant('singles.message_server'), data: [ response.body?.msg ?? ''] });
          this.showDialogPage=false;
          this.modalActionService.sendOpenCloseModalRegister(false);
          this.modalActionService.sendOpenCloseModalLogin(true);
        },
        error: (errorResponse) => {
          this.messageService.add({key: 'messages',severity:'error', summary:this.translate.instant('singles.message_server'),
            data: extractErrorResponse(errorResponse,false)})
          this.hasRegistrationErrors= true;
          this.ngxService.stop();
        }
      });
    }
  }

  ngOnInit(): void {
    this.subscriptions();
  }

  private subscriptions() {
    this.modalActionService.sendOpenCloseModalActivateObservable.subscribe((response) => {
      this.modalActionService.sendOpenCloseModalRegister(false);
      this.modalActionService.sendOpenCloseModalLogin(false);
      this.codeInsertForm.reset();
      this.showDialogPage= response.action;
      this.codeInsertForm.get('email')?.patchValue(response.email);
    });
  }

  private createForm() {
    this.codeInsertForm = this.formBuilder.group({
      email: [''],
      code_activate: [ null, [ Validators.required]],
    });
  }

  viewLogin() {
    this.modalActionService.sendOpenCloseModalRegister(false);
    this.modalActionService.sendOpenCloseModalActivate('',false);
    this.modalActionService.sendOpenCloseModalLogin(true);
  }
}
