import { HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';

export class HelperService {
  constructor() {}

  static options() {
    return new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/vnd.api+json',
      'Api-Client': environment.apiClient,
      'Api-Key': environment.apiKey,
      Authorization: 'Bearer ' + localStorage.getItem('access_token'),
    });
  }
}
