import { animate, group, query, style, transition, trigger } from "@angular/animations";

export const routerAnimations =
  trigger('routerAnimation', [
    transition('* <=> *', [
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          opacity: 0,
          transform: 'scale(0) translateY(100%)',
        }),],
        { optional: true}
      ),
      query(':enter', [
        style({ opacity: '0'}),
      ],
        { optional: true}
      ),
      group([
        query(':leave', [
          animate('300ms ease-out', style({ opacity: '0' })),
        ],
          { optional: true}
        ),
        query(':enter', [
          animate('300ms ease-out', style({ opacity: '1' })),
        ],
        { optional: true}
        ),
      ]),
    ]),
  ]);
