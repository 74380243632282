import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {HomeComponent} from './home/home.component';
import {WorkflowsComponent} from "./workflows/workflows.component";
import {MyProfileComponent} from "./my-profile/my-profile.component";
import {RatingServicesComponent} from "./rating-services/rating-services.component";
import { UserDataComponent } from './my-profile/user-data/user-data.component';
import {UpdateFormGlobalComponent} from "./update-form-global/update-form-global.component";

const routes: Routes = [
  {path: 'home', component: HomeComponent, data: {animation: '*'} },
  {
    path: 'tab-navigator',
    loadChildren: () => import('./tab-navigator/tab-navigator.module').then(m => m.TabNavigatorModule),
    data: {animation: '*'}
  },
  {path: 'workflows', component: WorkflowsComponent, data: {animation: '*'} },
  {path: 'my-profile', component: MyProfileComponent, data: {animation: '*'} },
  {path: 'profile', component: UserDataComponent, data: {animation: '*'} },
  {path: 'rating-services/:id', component: RatingServicesComponent, data: {animation: '*'} },
  {path: 'update-form-global/:disabledForm', component: UpdateFormGlobalComponent, data: {animation: '*'} },
  {path: '**', redirectTo: '/home', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
