export enum TabsIndex {
  tabGeneralData = 0,
  tabProform = 1,
  tabPayment = 2,
  tabAccurateData = 3,
  tabFilesLoad = 4,
  tabGetPermission = 5
}
export enum SubTabsIndex {
  subTabQuoted = 0,
  subTabSigned = 1,
  subTabTravellers = 2,
  subTabGrantors = 3,
  subTabCompanions = 4,
  subTabTravel = 5,
  subTabDocuments
}
