import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ITravelResponse, TravelResponse } from 'src/models/accurate-data/travel-response.model';
import { ITravel } from 'src/models/accurate-data/travel.model';
import { HelperService } from 'src/utils/helpers';
import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root'
})
export class TravelService extends AbstractService<ITravel> {

  static urlCreate = environment.urlServer.concat('api/add-data-trips');
  static urlGet = environment.urlServer.concat('api/guest/selects-basic/reasonTravel');
  static urlPut = environment.urlServer.concat('api/dataTrips');
  static urlGetTravel = environment.urlServer.concat('api/invoices');

  constructor(httpClient: HttpClient) {
    super(httpClient, TravelService.urlGet);
  }

  createTravel(entity: ITravel, invoice: number): Observable<HttpResponse<ITravelResponse>> {
    return this.http.post<ITravelResponse>(
      TravelService.urlCreate.concat(`/${invoice}`),
      entity,
      {
        observe: 'response',
        headers: HelperService.options()
      }
    );
  }
  putTravel(travel: ITravel, idTravel: number): Observable<HttpResponse<TravelResponse>> {
    return this.http.put<TravelResponse>(
      TravelService.urlPut.concat(`/${idTravel}`),
      travel,
      {
        observe: 'response',
        headers: HelperService.options()
      }
    );
  }
  getTravel(idInvoice: number): Observable<HttpResponse<ITravel[]>>{
    return this.http.get<ITravel[]>(
      `${TravelService.urlGetTravel}/${idInvoice}/dataTrips`,
    {
      observe: 'response',
      headers: HelperService.options()
    });
  }
}
