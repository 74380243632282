interface IShowMessages {
    error?: boolean;
    success?: boolean;
}

export class FormStatus {
    submitted: boolean;
    showMessages: IShowMessages;
    errors: string[];
    messages: string[];

    constructor(params?: { submitted?: boolean, showMessages?: IShowMessages, errors?: string[], messages?: string[] }) {
        this.submitted = (params && params.submitted) || false;
        this.showMessages = (params && params.showMessages) || {};
        this.errors = (params && params.errors) || [];
        this.messages = (params && params.messages) || [];
    }

    canShowErrors() {
        return this.showMessages.error && this.errors && this.errors.length > 0 && !this.submitted;
    }

    canShowSuccess() {
        return this.showMessages.success && this.messages && this.messages.length > 0 && !this.submitted;
    }

    onFormSubmitting() {
        this.errors = this.messages = [];
        this.submitted = true;
    }

    onFormSubmitResponse(params: { success: boolean, messages: string[] }) {
        this.submitted = false;
        if (params.success) {
            this.showMessages.success = true;
            this.messages = params.messages;
        } else {
            this.showMessages.error = true;
            this.errors = params.messages;
        }
    }
}
