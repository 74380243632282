import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {emailValidator} from 'src/directives/Validators/email.validator.directive';
import {LoginResponse} from 'src/models/authentication/login-response.model';
import {AuthenticationService} from 'src/services/authentication.service';
import {ModalActionsService} from "../../../services/message/modal-actions.service";
import {MessageService} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {extractErrorResponse} from "../../../utils/extractErrorResponse";
import {IEntity} from "../../../models/entity.model";
import {LoginActionsService} from "../../../services/message/login-actions.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  showDialogPage = false;
  errorLogin = false;
  loginForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
              private authenticationService:AuthenticationService,
              private modalActionService: ModalActionsService,
              private messageService: MessageService,
              private translate: TranslateService,
              private ngxService: NgxUiLoaderService,
              private loginActionService: LoginActionsService
  ) {
    this.loginForm= this.formBuilder.group({
      email: [null, [ Validators.required, emailValidator ]],
      password: [null, [ Validators.required ]]
    });
  }

  ngOnInit(): void {
    this.isLoginState();
    this.subscriptions();
  }

  private subscriptions(): void  {
    this.modalActionService.sendOpenCloseModalLoginObservable.subscribe((response) => {
      this.loginForm.reset();
      this.showDialogPage= response;
    });
  }

  getControlFormField(field: string): AbstractControl {
    return this.loginForm.controls[field];
  }

  onSubmit(): void {
    if (this.loginForm.valid) {
      this.ngxService.start();
      this.authenticationService.create(this.loginForm.value).subscribe({
        next: (response) => {
          this.ngxService.stop();
          this.onSaveSuccess(response.body as IEntity);
          this.showDialogPage=false;
        },
        error: (errorResponse) => {
          this.messageService.add({key: 'messages',severity:'error', summary:this.translate.instant('singles.message_server'), data: extractErrorResponse(errorResponse,false)});
          this.ngxService.stop();
          if(errorResponse.status===423){
            this.modalActionService.sendOpenCloseModalActivate( this.loginForm.value.email ?? '', true);
          }
          this.onSaveError();
        }
      });
    }
  }

  hasErrors(formField: string): boolean {
    return this.loginForm.touched || this.getControlFormField(formField).value;
  }

  hasCustomErrors(formField: string, customValidator: string): boolean {
    return this.getControlFormField(formField).value && this.getControlFormField(formField).errors?.[customValidator];
  }

  isLoginState(): void {
    this.authenticationService.isLogged().subscribe({
      next: (res) => {
        if (res.body) {
          localStorage.setItem('isLogging', JSON.stringify(true));
          localStorage.setItem(btoa('user'), btoa(JSON.stringify(res.body)));
          this.loginActionService.sendLoginAction(true);
        } else {
          localStorage.clear();
          sessionStorage.clear();
          localStorage.setItem('isLogging', JSON.stringify(false));
          this.loginActionService.sendLoginAction(false);
        }
      },
      error: () => localStorage.setItem('isLogging', JSON.stringify(false))
    });
  }

  protected onSaveSuccess(loginResponse: LoginResponse): void {
    if (loginResponse.refresh_token) {
      this.authenticationService.saveRefreshToken(loginResponse.refresh_token);
    }
    if (loginResponse.access_token) {
      this.authenticationService.saveToken(loginResponse.access_token);
    }
    this.errorLogin = false;
    let user = { email: this.loginForm.value.email, id: loginResponse.user?.id };
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('isLogging', JSON.stringify(true));
    localStorage.setItem(btoa('user'), btoa(JSON.stringify(loginResponse.user)));
    this.loginActionService.sendLoginAction(true);
    this.loginForm.reset();
  }

  protected onSaveError(): void {
    this.errorLogin = true;
    localStorage.setItem('isLogging', JSON.stringify(false));
    this.loginActionService.sendLoginAction(false);
    this.loginForm.reset()
  }

  public viewRegister(): void {
    this.modalActionService.sendOpenCloseModalLogin(false);
    this.modalActionService.sendOpenCloseModalActivate('',false);
    this.modalActionService.sendOpenCloseModalRegister(true);
  }
}
