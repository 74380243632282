import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {AbstractService} from "./abstract.service";
import {IDocument} from "../models/document.model";
import {HelperService} from "../utils/helpers";
import {Observable} from "rxjs";
import {IUser, User} from "../models/users.model";
import {environment} from "../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UserService extends AbstractService<IDocument> {

  private static urlUsers : string = environment.urlServer.concat('api/profile');

  constructor(httpClient: HttpClient) {
    super(httpClient, UserService.urlUsers);
  }

}
