import {Component, OnInit} from '@angular/core';
import { appConfigs } from '../../constants/initialize';
import {FormStatus} from '../../../utils/formStatus';
import {IUser, User} from "../../../models/users.model";
import {AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {UserService} from "../../../services/user.service";
import {take} from "rxjs";
import Swal from "sweetalert2";
import {extractErrorResponse} from "../../../utils/extractErrorResponse";
import {HttpErrorResponse} from "@angular/common/http";
import {ControlsComponent} from "../../shared/controls.component";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AuthenticationService} from "../../../services/authentication.service";
import {emailValidator} from "../../../directives/Validators/email.validator.directive";
import {MessageService} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";
import {General} from "../../../models/general.model";
import {Router} from "@angular/router";
import {LoginResponse} from "../../../models/authentication/login-response.model";

const lifeTimeModal = 20 * 1000;

@Component({
  selector: 'app-user-data',
  templateUrl: './user-data.component.html',
  styleUrls: ['./user-data.component.scss'],
})

export class UserDataComponent extends ControlsComponent implements OnInit {
  public titlePage = appConfigs.titlePageUserData;
  public isSaving = false;

  public new_password = "";
  public confirm_new_password = "";

  formStatus = new FormStatus();
  userForm: FormGroup;

  public user: IUser = {
    'data': [],
    'email': '',
    'first_name': '',
    'last_name': '',
    'phone': '',
    'full_name': '',
    'role': '',
    'password': '',
  };

  constructor(protected override ngxService: NgxUiLoaderService,
              protected override modalService: NgbModal,
              protected override formBuilder: FormBuilder,
              private userService: UserService,
              private authenticationService: AuthenticationService,
              private messageService: MessageService,
              private translate: TranslateService,
              private router: Router,
              ) {
    super(ngxService, modalService, formBuilder);
  }

  ngOnInit(): void {
    this.createForm();
    this.loadUser();
  }

  isEnabled(): boolean {
    return this.userForm?.valid && !this.formStatus.submitted;
  }

  loadUser(): void {
    this.ngxService.start();
    let userCache = localStorage.getItem(btoa('user'));
    this.formStatus.showMessages.error = false;
    this.formStatus.showMessages.success = false;
    this.formStatus.messages = [];
    this.action = appConfigs.actionUpdate;
    this.userForm.reset();
    if (userCache == undefined) {
      this.authenticationService.isLogged()
        .pipe(take(1)).subscribe(
        (response) => {
          this.user = response.body as IUser;
          this.userForm.patchValue(this.user);
          this.ngxService.stop();
        },
        (errorResponse: HttpErrorResponse) => {
          Swal.fire(extractErrorResponse(errorResponse, true));
          this.ngxService.stop();
        }
      );
    } else {
      this.user = JSON.parse(atob(userCache)) as IUser;
      this.userForm.patchValue(this.user);
      this.ngxService.stop();
    }
  }

  createForm(): void {
    this.userForm = this.formBuilder.group({
      id: [''],
      email: ['', [Validators.required, Validators.minLength(1), emailValidator]],
      first_name: ['', [Validators.required, Validators.minLength(3)]],
      last_name: ['', [Validators.required, Validators.minLength(3)]],
      birthdate: ['', [Validators.required, Validators.minLength(10)]],
      phone: ['', [Validators.required, Validators.minLength(3)]],
      password: ['', [Validators.required, Validators.minLength(5)]],
      new_password: ['', [Validators.minLength(5)]],
      confirm_new_password: ['', [Validators.minLength(5)]],
      role: ['user'],
    });

    this.userForm.addValidators(
      this.matchValidator(
        this.userForm.get('new_password') as AbstractControl,
        this.userForm.get('confirm_new_password') as AbstractControl
      ));
  }

  matchValidator(
    control: AbstractControl,
    controlTwo: AbstractControl
  ): ValidatorFn {
    return () => {
      let invalid: boolean = true;
      if (control.value !== controlTwo.value){
        let error = { match: invalid };
        controlTwo.setErrors(error);
        return error;
      }
      return null;
    };
  }

  createEditUser(user: User): void {
    user.role = 'user';
    this.formStatus.onFormSubmitting();
    this.ngxService.start();
    this.userService
      .update(user.id as number, user)
      .pipe(take(1))
      .subscribe(
        (response) => {
          const message = response.body as General;
          this.formStatus.onFormSubmitResponse({ success: true, messages: [message.data.msg] });
          this.messageService.add({
            key: 'messages',
            severity:'success',
            summary:this.translate.instant('singles.message_server'),
            data: [ message.data?.msg ?? ''],
            life: lifeTimeModal
          });
          let user: User = message.data.model.user;
          let token: LoginResponse = message.data.model.token;
          localStorage.removeItem(btoa('user'));
          localStorage.setItem(btoa('user'), btoa(JSON.stringify(user)));
          this.ngxService.stop();
        },
        (errorResponse: HttpErrorResponse) => {
          const messages: string[] = [this.translate.instant('errorMessages.loginError')];
          messages.push(extractErrorResponse(errorResponse, false));
          this.formStatus.onFormSubmitResponse({ success: false, messages: messages });
          this.messageService.add({
            key: 'messages',
            severity:'error',
            summary:this.translate.instant('singles.message_server'),
            data: messages,
            life: lifeTimeModal
          });
          this.ngxService.stop();
        }
      );
  }

  hasErrors(formField: string): boolean {
    return this.userForm.touched || this.getControlFormField(formField).value;
  }

  hasCustomErrors(formField: string, customValidator: string): boolean {
    return this.getControlFormField(formField).value && this.getControlFormField(formField).errors?.[customValidator];
  }

  getControlFormField(field: string): AbstractControl {
    return this.userForm.controls[field];
  }

}
