<div class="page_body">
  <app-header></app-header>
  <div class="position-relative" [@routerAnimation]="prepareRoute(outlet)">
    <router-outlet #outlet="outlet"></router-outlet>
    <app-registration></app-registration>
    <app-login></app-login>
    <app-activate></app-activate>
    <p-toast position="top-right" key="messages" [baseZIndex]="5000">
      <ng-template let-message pTemplate="message">
        <div class="flex flex-column" style="flex: 1">
            <h6>{{message.summary}}</h6>
            <div>
              <ul *ngIf="message.data">
                <li *ngFor="let error of message.data"><small >{{ error }}</small></li>
              </ul>
            </div>
        </div>
      </ng-template>
    </p-toast>
    <ngx-ui-loader></ngx-ui-loader>
  </div>
  <app-footer class="position-relative" ></app-footer>
</div>
