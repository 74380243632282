import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TravellerResponse } from 'src/models/accurate-data/traveller-response-model';
import { ITraveller, Traveller } from 'src/models/traveller.model';
import { HelperService } from 'src/utils/helpers';
import { AbstractService } from './abstract.service';
import {IInformationGlobal} from "../models/information-global.model";

@Injectable({
  providedIn: 'root'
})
export class TravellerService extends AbstractService<ITraveller> {

  static urlCreate = environment.urlServer.concat('api/add-travelers');
  static urlPut = environment.urlServer.concat('api/travelers');
  static urlGetTravelers = environment.urlServer.concat('api/invoices');
  static updateInformationGlobal = environment.urlServer.concat('api/invoices/updateInformationGlobal');

  constructor(httpClient: HttpClient) {
    super(httpClient, TravellerService.urlCreate);
  }

  createTraveller(entity: ITraveller, invoice: number): Observable<HttpResponse<TravellerResponse>> {
    return this.http.post<TravellerResponse>(
      TravellerService.urlCreate.concat(`/${invoice}`),
      entity,
      {
        observe: 'response',
        headers: HelperService.options()
      }
    );
  }
  putTraveller(traveller: ITraveller, idTraveller: number): Observable<HttpResponse<TravellerResponse>> {
    return this.http.put<TravellerResponse>(
      TravellerService.urlPut.concat(`/${idTraveller}`),
      traveller,
      {
        observe: 'response',
        headers: HelperService.options()
      }
    );
  }
  getTravelers(idInvoice: number): Observable<HttpResponse<Traveller[]>>{
    return this.http.get<Traveller[]>(
      `${TravellerService.urlGetTravelers}/${idInvoice}/travelers`,
    {
      observe: 'response',
      headers: HelperService.options()
    });
  }

  updateInformationGlobal(entity: IInformationGlobal): Observable<HttpResponse<any>> {
    return this.http.post<any>(
      TravellerService.updateInformationGlobal,
      entity,
      {
        observe: 'response',
        headers: HelperService.options()
      }
    );
  }
}
