import {Component, OnInit} from '@angular/core';
import { appConfigs } from '../constants/initialize';
import {HttpErrorResponse, HttpParams, HttpResponse} from "@angular/common/http";
import {RateQuestionsService} from "../../services/rate-questions.service";
import {IRateQuestions} from "../../models/rate-questions.model";
import {FormControl, Validators} from "@angular/forms";
import {IRatingService} from "../../models/rating-service.model";
import { ActivatedRoute } from '@angular/router';
import {IWorkflow} from "../../models/workflow.model";
import Swal from "sweetalert2";
import {extractErrorResponse} from "../../utils/extractErrorResponse";
import {TranslateService} from "@ngx-translate/core";
import {NgxUiLoaderService} from "ngx-ui-loader";

@Component({
  selector: 'app-rating-services',
  templateUrl: './rating-services.component.html',
  styleUrls: ['./rating-services.component.scss'],
})

export class RatingServicesComponent implements OnInit {
  public titlePage = appConfigs.titleRatingServices;
  public isSaving = false;
  public hasErrors = false;
  public baseQuestions: any = [];
  public starRating = 0;
  public isLoading = true;
  public isPending = false;
  public notFound = false;
  public isSending = false;
  public isSent = false;
  public statusMessage = "";

  public invoice_id: string = "";

  public questions: IRateQuestions[] = [];
  public questions_id = 0;

  constructor(
    private rateQuestionsService: RateQuestionsService,
    private activatedRoute:ActivatedRoute,
    private translate: TranslateService,
    protected ngxService: NgxUiLoaderService)
  { }

  ngOnInit(): void {
    this.loadItems();
    this.loadTrackId();
  }

  loadItems(): void {
    let setQuestions: IRateQuestions;
    let baseQuestionsModel: string [];
    let baseQuestionsCacheKey = 'baseQuestions';
    let baseQuestionsCache = sessionStorage.getItem(baseQuestionsCacheKey);
    if (baseQuestionsCache == undefined) {
      this.rateQuestionsService.listQuestions().subscribe((response: HttpResponse<IRateQuestions[]>) => {
        let baseQuestionsBodyTmp = response.body ?? [] ;
        if (baseQuestionsBodyTmp.length == 0) {
          Swal.fire(this.translate.instant('rating_services.error_getting_questions'));
        }
        setQuestions = baseQuestionsBodyTmp.pop() as IRateQuestions;
        this.questions_id = setQuestions.id as number;
        baseQuestionsModel = JSON.parse(setQuestions.questions_json as string);
        baseQuestionsModel.forEach( (item) => {
          this.baseQuestions.push({
            'question': item,
            'value': 0
          });
        });
        sessionStorage.setItem(baseQuestionsCacheKey, JSON.stringify(setQuestions));
      });
    } else {
      setQuestions = JSON.parse(baseQuestionsCache ?? "[]");
      this.questions_id = setQuestions.id as number;
      baseQuestionsModel = JSON.parse(setQuestions.questions_json as string);
      baseQuestionsModel.forEach( (item) => {
        this.baseQuestions.push({
          'question': item,
          'value': 0
        });
      });
    }

  }

  changeValue(index: number, value: number): void {
    this.baseQuestions[index].value = value;
  }

  saveRating() {
    if (this.baseQuestions.some((item: { value: number; }) => item.value == 0)) {
      Swal.fire(this.translate.instant('rating_services.evaluation_pending'));
      return;
    }
    this.ngxService.start();
    this.isSending = true;
    let entity: IRatingService = {
      'invoice_id' : this.invoice_id,
      'rate_questions_id' : this.questions_id,
      'rating_json' : JSON.stringify(this.baseQuestions),
      'notes' : '',
    };
    this.rateQuestionsService.saveRating(entity).subscribe((response: HttpResponse<IRatingService>) => {
      this.statusMessage = this.translate.instant('rating_services.evaluation_registered');
      Swal.fire(this.translate.instant(this.statusMessage));
      this.isSent = true;
        this.ngxService.stop();
    },
    (errorResponse: HttpErrorResponse) => {
      this.isSending = false;
      this.isSent = true;
      this.statusMessage = extractErrorResponse(errorResponse, true);
      this.ngxService.stop();
      Swal.fire(this.statusMessage);
    });
  }

  loadTrackId() {
    this.invoice_id = this.activatedRoute.snapshot.paramMap.get("id") as string;
    this.rateQuestionsService.isRatingValid(this.invoice_id).subscribe((response: HttpResponse<IWorkflow>) => {
      let rate = (response.body?.rate as boolean) ?? false;
      this.isPending = !rate;
      this.isLoading = false;
    },
      (errorResponse: HttpErrorResponse) => {
        this.isLoading = false;
        this.isPending = false;
        this.notFound = true;
        this.statusMessage = extractErrorResponse(errorResponse, true);
        Swal.fire(this.statusMessage);
      });
  }
}
