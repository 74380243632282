import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoginActionsService {
  private loginSubject = new Subject<boolean>();
  loginObservable = this.loginSubject.asObservable();

  public sendLoginAction(action: boolean) {
    this.loginSubject.next(action);
  }

}
