import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {AbstractService} from "./abstract.service";
import {ICompanion} from "../models/companion.model";
import {environment} from "../environments/environment";
import { Observable } from 'rxjs';
import { HelperService } from 'src/utils/helpers';
import { CompanionResponse } from 'src/models/accurate-data/companion-response';

@Injectable({
  providedIn: 'root'
})
export class CompanionService extends AbstractService<ICompanion> {

  static urlGet = environment.urlServer.concat('api/guest/selects-basic/partner');
  static urlPost = environment.urlServer.concat('api/add-companions');
  static urlPut = environment.urlServer.concat('api/companions');
  static urlGetCompanions = environment.urlServer.concat('api/invoices');

  constructor(httpClient: HttpClient) {
    super(httpClient, CompanionService.urlGet);
  }
  createCompanion(entity: ICompanion, invoice: number): Observable<HttpResponse<CompanionResponse>> {
    return this.http.post(
      CompanionService.urlPost.concat(`/${invoice}`),
      entity,
      {
        observe: 'response',
        headers: HelperService.options()
      }
    );
  }
  putCompanion(companion: ICompanion, idCompanion: number): Observable<HttpResponse<CompanionResponse>> {
    return this.http.put<CompanionResponse>(
      CompanionService.urlPut.concat(`/${idCompanion}`),
      companion,
      {
        observe: 'response',
        headers: HelperService.options()
      }
    );
  }
  getTravelers(idInvoice: number): Observable<HttpResponse<ICompanion[]>>{
    return this.http.get<ICompanion[]>(
      `${CompanionService.urlGetCompanions}/${idInvoice}/companions`,
    {
      observe: 'response',
      headers: HelperService.options()
    });
  }
}
