<div class="container text-profile">
  <div class="row text-center">
    <div class="col-md-12">
      <h4 class="card-title title-profile"> {{ titlePage | translate }} </h4>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <form novalidate [formGroup]="userForm" (submit)="createEditUser(userForm.value)">
        <div class="row">
          <div class="col-md-6 input-center">
            <ng-container *ngIf="formStatus">
              <div *ngIf="formStatus.canShowErrors()" class="alert alert-danger" role="alert">
                <ul *ngIf="formStatus.canShowErrors()">
                  <li *ngFor="let error of formStatus.errors" class="text-danger"><small >{{ error }}</small></li>
                </ul>
              </div>

              <div *ngIf="formStatus.canShowSuccess()" class="alert alert-info" role="alert">
                <span *ngFor="let message of formStatus.messages"><small>{{ message }}</small><br/></span>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="col-md-6 input-center">
              <div class="form-group">
                <label for="email" class="label">{{'user_data.email' | translate}}: </label>
                <input id="email" type="text" value="{{user.email}}"
                       formControlName="email" class="form-control" [readOnly]="true">
                <div *ngIf="hasErrors('email')">
                  <span class="col-12 text-danger p-0" *ngIf="getControlFormField('email').errors?.['required']">
                    <small>{{ 'errorMessages.required' | translate }}</small>
                  </span>
                  <span class="col-12 text-danger p-0" *ngIf="hasCustomErrors('email', 'pattern')">
                    <small>{{ 'errorMessages.invalid_email' | translate }}</small>
                  </span>
                </div>
              </div>
            </div>

            <div class="col-md-6 input-center pb-md-5">
              <div class="form-group">
                <label for="password" class="label">{{'user_data.current_password' | translate}}: </label>
                <input id="password" type="password" value="{{user.password}}"
                       formControlName="password" class="form-control">
                <div *ngIf="hasErrors('password')">
                  <span class="col-12 text-danger p-0" *ngIf="getControlFormField('password').errors?.['required']">
                    <small>{{ 'errorMessages.required' | translate }}</small>
                  </span>
                  <span class="col-12 text-danger p-0" *ngIf="getControlFormField('password').errors?.['minlength']">
                    <small>{{ 'errorMessages.min5' | translate }}</small>
                  </span>
                </div>
              </div>
            </div>

            <div class="col-md-6 input-center">
              <div class="form-group">
                <label for="first_name" class="label">{{'user_data.first_name' | translate}}: </label>
                <input id="first_name" type="text" value="{{user.first_name}}"
                       formControlName="first_name" class="form-control">
                <div *ngIf="hasErrors('first_name')">
                  <span class="col-12 text-danger p-0" *ngIf="getControlFormField('first_name').errors?.['required']">
                    <small>{{ 'errorMessages.required' | translate }}</small>
                  </span>
                </div>
              </div>
            </div>

            <div class="col-md-6 input-center">
              <div class="form-group">
                <label for="last_name" class="label">{{'user_data.last_name' | translate}}: </label>
                <input id="last_name" type="text" value="{{user.last_name}}"
                       formControlName="last_name" class="form-control">
                <div *ngIf="hasErrors('last_name')">
                  <span class="col-12 text-danger p-0" *ngIf="getControlFormField('last_name').errors?.['required']">
                    <small>{{ 'errorMessages.required' | translate }}</small>
                  </span>
                </div>
              </div>
            </div>

            <div class="col-md-6 input-center">
              <div class="form-group">
                <label for="birthdate" class="label">{{'user_data.birthdate' | translate}}: </label>
                <input id="birthdate" type="date" value="{{user.birthdate}}"
                       formControlName="birthdate" class="form-control">
                <div *ngIf="hasErrors('birthdate')">
                  <span class="col-12 text-danger p-0" *ngIf="getControlFormField('birthdate').errors?.['required']">
                    <small>{{ 'errorMessages.required' | translate }}</small>
                  </span>
                </div>
              </div>
            </div>

            <div class="col-md-6 input-center">
              <div class="form-group">
                <label for="phone" class="label">{{'user_data.phone' | translate}}: </label>
                <input id="phone" type="text" value="{{user.phone}}"
                       formControlName="phone" class="form-control">
                <div *ngIf="hasErrors('phone')">
                  <span class="col-12 text-danger p-0" *ngIf="getControlFormField('phone').errors?.['required']">
                    <small>{{ 'errorMessages.required' | translate }}</small>
                  </span>
                </div>
              </div>
            </div>

            <div class="col-md-6 input-center pt-md-5">
              <label class="font-weight-bold"> {{ 'user_data.change_password' | translate }} </label>
            </div>

            <div class="col-md-6 input-center">
              <div class="form-group">
                <label for="new_password" class="label">{{'user_data.new_password' | translate}}: </label>
                <input id="new_password" type="password" value="{{new_password}}"
                       formControlName="new_password" class="form-control">
                <div *ngIf="hasErrors('new_password')">
                  <span class="col-12 text-danger p-0" *ngIf="getControlFormField('new_password').errors?.['minlength']">
                    <small>{{ 'errorMessages.min5' | translate }}</small>
                  </span>
                </div>
              </div>
            </div>

            <div class="col-md-6 input-center">
              <div class="form-group">
                <label for="confirm_new_password" class="label">{{'user_data.confirm_new_password' | translate}}: </label>
                <input id="confirm_new_password" type="password" value="{{confirm_new_password}}"
                       formControlName="confirm_new_password" class="form-control">
                <div *ngIf="hasErrors('confirm_new_password')">
                  <span class="col-12 text-danger p-0" *ngIf="getControlFormField('confirm_new_password').errors?.['minlength']">
                    <small>{{ 'errorMessages.min5' | translate }}</small>
                  </span>
                  <span class="col-12 text-danger p-0" *ngIf="hasCustomErrors('confirm_new_password', 'match')">
                    <small>{{ 'errorMessages.password_not_coincidence' | translate }}</small>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row text-center">
          <div class="col-md-12 mb-4">
            <button [class.btn-pulse]="formStatus.submitted"
                    [disabled]="!isEnabled()"
                    type="submit"
                    class="btn">
              <img src="../../../assets/images/save.png" class="img-button" alt="save form">
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
