import {Component} from '@angular/core';
import { appConfigs } from '../constants/initialize';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss'],
})

export class MyProfileComponent {
}
